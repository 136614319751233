import 'bootstrap/dist/css/bootstrap.min.css';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './assets/css/custom_style.css';
import './assets/css/responsive.css';
import {onMessageListener} from './firebase';

import React, {useEffect, useState, lazy, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';

// Public imports
import HomePage from './pages/homepage';
import AccountVerify from './pages/public/accountVerify';
import BridgePage from './pages/public/bridge';
import ConfirmPassword from './pages/public/confirmPassword';
import EmailPhoneOtp from './pages/public/emailPhoneOtp';
import EmailPhoneVerify from './pages/public/emailPhoneVerify';
import ForgotPassOtp from './pages/public/forgotPasswordOtp/otpFogotPass';
import ForgotSuccess from './pages/public/forgotSuccess';
import LandingPage from './pages/public/landing';
import LoginPage from './pages/public/login';
import Otp from './pages/public/otp';
import SendEmail from './pages/public/sendEmailOpt';
import SignupPage from './pages/public/signup';
import SplashPage from './pages/public/splash';
import TermsAndConditions from './pages/public/terms';
import PrivacyPolicy from './pages/public/privacy';

// Router imports
import PrivateRoute from './router/privateRoute';
import PublicRoute from './router/publicRoute';
import PrivateJobPosterRoute from "./router/privateJobPosterRoute";
import PrivateJobSeekerRoute from "./router/privateJobSeekerRoute";

// Private imports (lazy loaded)
import Chat from './pages/private/Chat/Chat';
import AppleAndGooglePay from './pages/private/applePay/applePay';
import ChangPassword from './pages/private/changPassword';
import ChooseIndustry from './pages/private/chooseIndustry';
import CompanySize from './pages/private/companySize';
import Credit from './pages/private/credit';
import EducationLevel from './pages/private/educationLevel';
// import EnhanceProfile from './pages/private/enhanceProfile';
import EnhanceProfile from './pages/private/enhanceProfile/boost-profile';
import FeedBack from './pages/private/feedback';
import HardStepOne from './pages/private/hardStep1';
import HardStepTwo from './pages/private/hardStep2';
import HardStepThree from './pages/private/hardStep3';
import HardStepFour from './pages/private/hardStep4';
import HardStepFive from './pages/private/hardStep5';
import JobDescription from './pages/private/jobDescriptionPage/jobDescription';
import MatchesFound from './pages/private/jobOfferPage/jobOfferPage';
import CurrentMatches from './pages/private/JobPosterPages/currentMatches/currentMatches';
import InterstedCandidates from './pages/private/JobPosterPages/interstedPage/jobPosterintersted';
import JobDetailsPage from './pages/private/JobPosterPages/jobDetailsPage/jobDetalisPage';
import CompanyInfo from './pages/private/JobPosterPages/jobPosterCompanyInfo/companyInfo';
import MatchesFoundJobPoster from './pages/private/JobPosterPages/jobPosterMatchesScreen/matchesFoundJobPoster';
import PaymentPlanScreen from './pages/private/JobPosterPages/jobPosterPaymentPlan/paymentPlanScreen';
import PayAsYouGoScreen from './pages/private/JobPosterPages/jobPosterPaymentPlan/payAsYouGoScreen';
import MutualInterst from './pages/private/JobPosterPages/mutualInterst/mutualInterest';
import ShortListed from './pages/private/JobPosterPages/shortListed/shortListed';
import JobRole from './pages/private/jobRole';
import JobTitle from './pages/private/jobTitle';
import StartInfo from './pages/private/landing';
import Location from './pages/private/location';
import MainMenu from './pages/private/MainMenu';
import Notification from './pages/private/notification';
import PaymentGateway from './pages/private/paymentContext/paymentGateway';
import PaymentMethod from './pages/private/paymentMethod';
import ProfileComplete from './pages/private/profileComplete';
import ProfileCompleteCongrats from './pages/private/profileCompleteCongrats';
import ProfileUpdate from './pages/private/profileUpdate';
import VerifyInfo from './pages/private/profileUpdate/otp';
import ProfileView from './pages/private/ProfileView';
import PurchaseComplete from './pages/private/purchaseComplete';
import RoleStepComplete from './pages/private/roleStepComplete';
import RoleStepCompletePoster from './pages/private/roleStepCompletePoster';
import Salary from './pages/private/salary';
import SelfAssestOne from './pages/private/selfAssest1';
import SkillLevel from './pages/private/skillLevel';
import SkillStep2 from './pages/private/skillStep2';
import SoftSkillStep2 from './pages/private/softSkillStep2';
import UploadVideo from './pages/private/uploadVideo';
import Wishlist from './pages/private/wishlist';
import TrackFeedback from './pages/private/jobDescriptionPage/TrackFeedback';
import JobsHomePage from './pages/private/JobPosterPages/jobsHomepage';
import JobPoster from './pages/private/jobPoster';
import CompaniesPage from './pages/private/JobPosterPages/companiesPage/companiesPage';
import AddOrEditCompanyPage from './pages/private/JobPosterPages/addOrEditCompanyPage/addOrEditCompanyPage';
import CompanyPage from './pages/private/JobPosterPages/companyPage/companyPage';
import JobMatches from './pages/private/JobPosterPages/jobMatches';
import ViewProfile from './pages/private/JobPosterPages/viewProfile';

function App() {
	const userAuth = useSelector(state => state.UserAuth);

	const isInfo = userAuth?.userData?.payload?.user?.selfAssessmentResult
		? userAuth?.userData?.payload?.user?.selfAssessmentResult
		: false;

	const onboardingSteps = userAuth?.userData?.payload?.user?.selfAssessmentResultSteps;
	const userType = userAuth?.userData?.payload?.user?.type;

	// console.log(isInfo, 'info______');
	// console.log(onboardingSteps, 'onboardingSteps______');

	const userTypes = useSelector(state => state.userType.userType);
	// console.log(userTypes.payload, 'userType.payload______');

	const [isLogin, setIsLogin] = useState(false);
	const [loading, setLoading] = useState(true);

	onMessageListener().then(payload => {
		//setNotification({title: payload.notification.title, body: payload.notification.body})
		// setShow(true);
		// console.log(payload);
		toast.success(payload.notification.body, {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}).catch(err => console.log('failed: ', err));

	const checkLoggedIn = () => {
		if (userAuth.userData !== null) {
			// console.log('get new respose', userAuth);
			setIsLogin(true);
		} else {
			setIsLogin(false);
		}
		setLoading(false);
	};

	useEffect(() => {
		checkLoggedIn();
	}, [userAuth]);

	return loading ?
		(
			<div className="d-flex justify-content-center align-items-center LoaderCenter">
				{' '}
				<div className="block">
					<span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true"/>
					<h6 className="LoaderText">Loading...</h6>
				</div>
			</div>
		) : (
			<div className="App">
				<Routes>
					<Route element={<PublicRoute isAuthenticated={isLogin}/>}>
						<Route path="/" element={<SplashPage/>}/>
						<Route path="landing" element={<LandingPage/>}/>
						<Route path="app" element={<BridgePage/>}/>
						<Route path="login" element={<LoginPage/>}/>
						<Route path="signup" element={<SignupPage/>}/>
						<Route path="emailPhoneVerify" element={<EmailPhoneVerify/>}/>
						<Route path="phoneOtp" element={<Otp/>}/>
						<Route path="emailOtp" element={<EmailPhoneOtp/>}/>
						<Route path="accountVerify" element={<AccountVerify/>}/>
						<Route path="sendEmail" element={<SendEmail/>}/>
						<Route path="otpVerfication" element={<ForgotPassOtp/>}/>
						<Route path="otp/resend" element={<ForgotPassOtp/>}/>
						<Route path="cofirmPassword" element={<ConfirmPassword/>}/>
						<Route path="forgotSuccess" element={<ForgotSuccess/>}/>
						<Route path="terms" element={<TermsAndConditions/>}/>
						<Route path="privacy" element={<PrivacyPolicy/>}/>
					</Route>

					<Route element={<PrivateJobPosterRoute isAuthenticated={isLogin} userType={userType}/>}>
						<Route path="jobPoster" element={<JobPoster/>}>
							<Route path="" element={<CompaniesPage/>}/>
							<Route path="companies" element={<CompaniesPage/>}/>
							<Route path="addCompany" element={<AddOrEditCompanyPage/>}/>
							<Route path="editCompany/:id" element={<AddOrEditCompanyPage/>}/>
							<Route path="company/:id" element={<CompanyPage/>}/>
							<Route path="company/:companyId/:jobId" element={<JobMatches/>}/>
							<Route path="company/:companyId/:jobId/:userId" element={<ViewProfile/>}/>
						</Route>
					</Route>

					<Route element={<PrivateJobSeekerRoute isAuthenticated={isLogin} userType={userType}/>}>
						<Route path="homepage" element={<HomePage/>}>
							{/* on-boarding steps continuations  --start */}
							{isInfo === false && onboardingSteps === 0 ? (<Route path="" element={<StartInfo/>}/>) : null}
							{isInfo === false && onboardingSteps === 1 ? (<Route path="" element={<JobTitle/>}/>) : null}
							{isInfo === false && onboardingSteps === 2 ? (<Route path="" element={<ChooseIndustry/>}/>) : null}
							{isInfo === false && onboardingSteps === 3 ? (<Route path="" element={<EducationLevel/>}/>) : null}
							{isInfo === false && onboardingSteps === 4 ? (<Route path="" element={<SkillLevel/>}/>) : null}
							{isInfo === false && onboardingSteps === 5 ? (<Route path="" element={<Location/>}/>) : null}
							{isInfo === false && onboardingSteps === 6 ? (<Route path="" element={<CompanySize/>}/>) : null}
							{isInfo === false && onboardingSteps === 7 ? (<Route path="" element={<Salary/>}/>) : null}
							{isInfo === false && onboardingSteps === 8 ? (<Route path="" element={<SkillStep2/>}/>) : null}
							{isInfo === false && onboardingSteps === 9 ? (<Route path="" element={<SoftSkillStep2/>}/>) : null}
							{isInfo === false && onboardingSteps === 10 ? (<Route path="" element={<SelfAssestOne/>}/>) : null}
							{userTypes.payload === 'jobSeeker' && isInfo === false && onboardingSteps === 11 ? (<Route path="" element={<SelfAssestOne/>}/>) : null}
							{userTypes.payload === 'jobSeeker' && isInfo === false && onboardingSteps === 12 ? (<Route path="" element={<SelfAssestOne/>}/>) : null}
							{userTypes.payload === 'jobSeeker' && isInfo === false && onboardingSteps === 13 ? (<Route path="" element={<SelfAssestOne/>}/>) : null}
							{userTypes.payload === 'jobSeeker' && isInfo === false && onboardingSteps === 14 ? (<Route path="" element={<SelfAssestOne/>}/>) : null}
							{/* on-boarding steps continuations  --end */}
							{userTypes.payload === 'jobSeeker' ?
								(<Route path="" element={<MainMenu/>}/>) 
								:
								(<Route path="" element={<MatchesFoundJobPoster/>}/>)
							}
							{!isInfo && <Route path="" element={<StartInfo/>}/>}
						</Route>
						<Route path="/homepage/JobsHomePage" element={<JobsHomePage/>}/>
					</Route>

					<Route element={<PrivateRoute isAuthenticated={isLogin}/>}>
						<Route path="jobRole" element={<JobRole/>}/>
						<Route path="jobTitle" element={<JobTitle/>}/>
						<Route path="chooseIndustry" element={<ChooseIndustry/>}/>
						<Route path="educationLevel" element={<EducationLevel/>}/>
						<Route path="skillLevel" element={<SkillLevel/>}/>
						<Route path="location" element={<Location/>}/>
						<Route path="companySize" element={<CompanySize/>}/>
						<Route path="salary" element={<Salary/>}/>
						<Route path="roleStepComplete" element={<RoleStepComplete/>}/>
						<Route path="skillStep" element={<SkillStep2/>}/>
						<Route path="softSkillStep" element={<SoftSkillStep2/>}/>
						<Route path="roleStepCompleted" element={<RoleStepCompletePoster/>}/>
						<Route path="selfAssest" element={<SelfAssestOne/>}/>
						{/* // This part is only for Job seeker */}
						<Route path="hardStep1" element={<HardStepOne/>}/>
						<Route path="hardStep2" element={<HardStepTwo/>}/>
						<Route path="hardStep3" element={<HardStepThree/>}/>
						<Route path="hardStep4" element={<HardStepFour/>}/>

						{/* //Jop poster route will be redirectd to this page  after self HardStep Five */}
						<Route path="hardStep5" element={<HardStepFive/>}/>
						<Route path="companyInfo" element={<CompanyInfo/>}/>
						<Route path="profileComplete" element={<ProfileComplete/>}/>
						<Route path="profile" element={<ProfileView/>}/>
						<Route path="profileCompleteCongrats" element={<ProfileCompleteCongrats/>}/>
						<Route path="boostProfile" element={<EnhanceProfile/>}/>
						<Route path="uploadVideo" element={<UploadVideo/>}/>
						<Route path="paymentMethod" element={<PaymentMethod/>}/>
						<Route path="paymentGatewayCard" element={<PaymentGateway/>}/>
						<Route path="paymentGateway" element={<AppleAndGooglePay/>}/>
						{/* <Route path="purchaseMethod" element={<PurchaseMethod />} /> */}
						<Route path="purchaseComplete" element={<PurchaseComplete/>}/>
						<Route path="userProfile" element={<MatchesFound/>}/>
						<Route path="profileUpdate" element={<ProfileUpdate/>}/>
						<Route path="verifyInfo" element={<VerifyInfo/>}/>
						<Route path="jobOffer" element={<MatchesFound/>}/>
						<Route path="chanPassword" element={<ChangPassword/>}/>
						<Route path="feedback" element={<FeedBack/>}/>
						<Route path="mainMenu/:id" element={<MainMenu/>}/>
						<Route path="mainMenu" element={<MainMenu/>}/>
						{/* //After profile complete jobPoster will navigate to this HomePage */}
						<Route path="jobDetails" element={<JobDetailsPage/>}/>
						<Route path="jobDescription" element={<JobDescription/>}/>
						<Route path="trackFeedback" element={<TrackFeedback/>}/>

						{/* //Todo - navigate and use these where needed create route  job poster page no 50 to 58 */}
						{/* <Route path="" element={<JobPosterProfileComplete />} /> */}
						<Route path="paymentOption/new" element={<PaymentPlanScreen/>}/>
						<Route path="paymentOption" element={<PaymentPlanScreen/>}/>
						<Route path="payAsYouGoScreen" element={<PayAsYouGoScreen/>}/>
						<Route path="addedJobList" element={<MatchesFoundJobPoster/>}/>

						<Route path="currentMatches" element={<CurrentMatches/>}/>
						<Route path="interstedProfiles" element={<InterstedCandidates/>}/>
						<Route path="mutualInterest" element={<MutualInterst/>}/>
						<Route path="shortListed" element={<ShortListed/>}/>
						<Route path="chat" element={<Chat/>}/>
						<Route path="wishlist" element={<Wishlist/>}/>
						<Route path="credit" element={<Credit/>}/>
						<Route path="notification" element={<Notification/>}/>
					</Route>
				</Routes>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
		);
}

export default App;
