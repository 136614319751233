import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { userType } from '../../../redux/actions';
import brand from '../../../assets/images/logo-icon-blk.png';
import { useSelector, useDispatch } from 'react-redux';
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  API_COMMON_ONBOARDING_STEPS,
  postApi,
  postApiPoster,
  SOFT_AND_HARD_SKILLS_LIST,
  getApi,
} from '../../../utils';
import { toast } from 'react-toastify';
import GoBack from '../../../components/GoBack';
import { saveUserInfo } from '../../../redux/actions';
import Fuse from 'fuse.js';

function SkillTwo() {
  const { state } = useLocation();
  const jobId = state.jobId;
  const [hardSkillForInputUI, sethardSkillForInputUI] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [listedOptions, setListedOptions] = useState([]);
  const [isFetchedData, setFetchedData] = useState(false);
  const [isAddButton, setAddButton] = useState(false);
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userType = useSelector(state => state.userType.userType);
  const [userCreatedRole, setUserCreatedRole] = useState('');
  const [currentAddedSkill, setCurrentAddedSkill] = useState([]);
  const [onTapSuggestionCalled, setonTapSuggestionCalled] = useState(false);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

  const fuseOptions = {
    keys: ['name'], // Define the property to search within the objects (here we use 'name')
    threshold: 0.3, // Adjust the threshold for fuzzy matching (0.0 to 1.0)
  };




  const navigation = useNavigate();
  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: 'hardskill',
    };
    if (userType.payload === 'jobSeeker') {
      postApi(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = response => {
    setFetchedData(true);
    setLoading(false);
    if (response.status === 200) {
      console.log(response.data.result, 'title');
      // setJobSeekerTitle(response.data.result.question);
    }
  };
  const errorFunction = error => {
    setLoading(false);
    setFetchedData(true);
    console.log(error, 'drerer');
  };
  const successFunctionOptions = response => {
    console.log(response, 'companySize');
    setLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      let dataWithExperienceValue = [];
      let dataComingIntial = response.data.result;
      dataComingIntial.map(data =>
        dataWithExperienceValue.push({
          _id: data._id,
          option: data.option,
          experience: 0,
        })
      );
      setListedOptions(dataWithExperienceValue);
    }

    // console.log(response);
  };
  const errorFunctionOptions = error => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };

  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******naviagte to another page***********************************//
  const savingInfo = () => {
    if (currentAddedSkill.length > 0) {

      const skill = [];
      currentAddedSkill.map(data =>
        skill.push({
          id: data._id,
          skill: {
            explevel: data.level,
            option: data.option,
            exp: data.experience + ' years',
          },
        })
      );
      setLoading(true);
      updateOnBoardingSteps(skill);

      // navigation('../softSkillStep', {
      //   state: { ...routerData, hardSkill: skill },
      // });
    } else {
      toast.error('Please add hard skill!', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };


  const getValue = (data, value) => {
    const index = currentAddedSkill.findIndex(item => item.option === data);
    if (index === -1) return;
  
    const skill = currentAddedSkill[index];
    let level = '';
    let experience = '';
    let scrollValue = value; // Always set the scrollValue to the slider value
  
    if (value <= 2) {
      level = 'Entry';
      experience = '0-2';
    } else if (value <= 5) {
      level = 'Mid';
      experience = '3-5';
    } else if (value <= 9) {
      level = 'Senior';
      experience = '6-9';
    } else {
      level = 'Expert';
      experience = '10+';
    }
  
    // Update the skill with the new values
    currentAddedSkill[index] = {
      ...skill,
      level,
      experience,
      scrollValue, // Update the scroll value directly
    };
  
    setCurrentAddedSkill([...currentAddedSkill]); // Trigger re-render
  };

  useMemo(() => {
    if (userCreatedRole !== '') {
      console.log('adding 0------> ', userCreatedRole);
      const userSearch = userCreatedRole.toLowerCase();
      const data = listedOptions.filter(
        data => data.option.toLowerCase() === userSearch.toLowerCase()
      );
      // setUserCreatedRole('');
      console.log('data ---', data);
      if (data.length === 0) {
        setAddButton(true);
      } else {
        console.log('this is the currently added skill', currentAddedSkill);
        setAddButton(false);
        // let check = currentAddedSkill.map(skill => skill.option === data.map(el => el.option));
        const check = currentAddedSkill.findIndex(e => e.option.toLowerCase() === userSearch);
        console.log('check', check);
        if (check > -1) {
          toast.error('This skill is already in list!', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setCurrentAddedSkill([...currentAddedSkill, ...data]);
        }
      }
    }
  }, [onTapSuggestionCalled]);


  const addNewSkill = e => {
    e.preventDefault();
    if (currentAddedSkill.filter((skill) => skill.option == userCreatedRole.toLowerCase()).length == 0) {
      if (userCreatedRole) {
        setLoading(true);
        if (userType.payload === 'jobSeeker') {
          const obj = {
            option: userCreatedRole,
            category: 'hardskill',
            usertype: 1,
            status: '2',
            userId: userType.payload._id,
            addedby: '2',
          };
          postApi(API_SEEKER_ADD_JOB_ROLE, obj, userToken, addHardSkillSuccess, addHardSkillError);
        } else {
          const obj = {
            option: userCreatedRole,
            category: 'hardskill',
            usertype: 2,
            status: '2',
            userId: userType.payload._id,
            addedby: '2',
          };
          postApi(API_SEEKER_ADD_JOB_ROLE, obj, userToken, addHardSkillSuccess, addHardSkillError);
        }
        sethardSkillForInputUI('')
      }
    } else {
      setLoading(false);
      toast.error("skill already added", {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }


  };
  const addHardSkillSuccess = res => {
    if (res.status === 200) {
      setLoading(false);
      console.log('get response value', res);
      toast.success('Your skill added succefully!', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const userValue = 2;
      const range = '0-';
      const append = range.concat(userValue);
      const data = {
        addedby: '3',
        category: 'hardskill',
        name: 'Unknown',
        option: userCreatedRole,
        status: 1,
        usertype: '1',
        _id: res.data.data._id,
        level: 'Entry',
        experience: append,
        scrollValue: 0,
      };
      console.log('get data value', data);
      setCurrentAddedSkill([...currentAddedSkill, data]);
      setUserCreatedRole('');
      setAddButton(false);

      setListedOptions([...listedOptions, data]);
    }
  };
  const addHardSkillError = err => {
    setLoading(false);
    toast.error(err.response.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const successCallback = data => {
    var skills = [];
    console.log("response for hard skills", data.data.result);
    for (var i = 0; i < data.data.result.length; i++) {
      skills.push(data?.data?.result[i].option);
    }
    setHardSkillLists(skills);
  };
  const errorCallback = error => {
    console.log('error-------', error);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  const [hardSkillLists, setHardSkillLists] = useState([]);
  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState('');
  const [suggestionOff, setSuggestionOff] = useState(false);

  const fuse = new Fuse(hardSkillLists, fuseOptions);

  const [matchesSkills, setmatchesSkills] = useState([]);



  // useEffect(() => {
  //   const obj = {
  //     category: 'hardskill',
  //   };
  //   postApi(API_SEEKER_JOB_ROLE_OPTIONS, obj, userToken, successCallback, errorCallback);
  // }, []);

  // useEffect(() => {
  //   if (userCreatedRole) {
  //     setSelectedFromSuggestion('');
  //   }
  // }, [userCreatedRole, selectedFromSuggestion]);




  const updateOnBoardingSteps = (hardskills) => {

    const obj = {
      jobId: jobId,
      "userId": userId,
      "hardSkill": hardskills,
      "userType": userType.payload,
      "stepNumber": 9,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, successFunctionOnboardingSteps, errorSavingData);


  }
  const userAuthInfo = useSelector(state => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = res => {

    setLoading(false);

    if (res.status === 200) {

      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 9;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation('../softSkillStep', {state: {jobId: jobId}});


    }

  }

  const errorSavingData = err => {
    toast.error(err.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  useEffect(() => {

    if (userCreatedRole != '') {
      const searchResults = userCreatedRole ? fuse.search(userCreatedRole) : hardSkillLists;
      var temp = [];
      for (var i = 0; i < searchResults?.length; i++) {
        temp.push(searchResults[i].item);
      }
      setmatchesSkills(temp)
    }

  }, [userCreatedRole])

  useEffect(() => {
    console.log('currentAddedSkill: ', currentAddedSkill)
  }, [currentAddedSkill])

  return (
    <React.Fragment>
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
          {' '}
          <div className='block'>
            <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
            <h6 className='LoaderText'>Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id='skills-steps'>
          <GoBack />
          <div className='discover-personality-top-cnt'>
            <div className='container'>
              <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-lg-8  text-center skills-step-des'>
                  <img src={brand} alt='brand' className='w-80px' />
                  {userType.payload === 'jobSeeker' ? (
                    <h3 className='skill-steps-heading'>
                      Add up to 10 hard skills and rate your proficiency in them
                    </h3>
                  ) : (
                    <h3 className='skill-steps-heading'>
                      Add any hard skills required and give a proficiency level for each
                    </h3>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!--discover-personality-top-cnt--> */}

          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-2' />
              <div className='col-lg-8'>
                <div className='card' style={{ borderWidth: 0 }}>
                  {/* <form id='msform' > */}
                  <form id='msform' onSubmit={addNewSkill}>
                    <fieldset>
                      <div className='form-card'>
                        <div className='enter-hard-skills'>
                          <h5>
                            <strong>Hard skills</strong> are the
                            <br />
                            specific skills you use on the job.
                          </h5>
                          <div className='d-flex position-relative'>
                            <input
                              type='text'
                              id='name'
                              name='name'
                              placeholder='Type your hard skills here...'
                              required=''
                              onFocus={event => {
                                event.target.setAttribute('autocomplete', 'off');
                                console.log(event.target.autocomplete);
                              }}
                              onKeyDown={handleKeyDown}
                              value={hardSkillForInputUI}
                              onChange={e => {

                                const { value } = e.target;
                                const regex = /^[a-zA-Z\s]*$/; // Regex pattern that matches only alphabets and spaces

                                if (regex.test(value)) {
                                  setAddButton(true);
                                  setUserCreatedRole(e.target.value);
                                  setSuggestionOff(false);
                                  sethardSkillForInputUI(e.target.value);
                                }
                                if (value === '') {
                                  setAddButton(false);
                                }

                              }}
                            />

                            {isAddButton && (
                              <button className='mx-1 btn btn-outline-primary' type='submit'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-plus-square-dotted'
                                  viewBox='0 0 16 16'
                                >
                                  <path d='M2.5 0c-.166 0-.33.016-.487.048l.194.98A1.51 1.51 0 0 1 2.5 1h.458V0H2.5zm2.292 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zm1.833 0h-.916v1h.916V0zm1.834 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zM13.5 0h-.458v1h.458c.1 0 .199.01.293.029l.194-.981A2.51 2.51 0 0 0 13.5 0zm2.079 1.11a2.511 2.511 0 0 0-.69-.689l-.556.831c.164.11.305.251.415.415l.83-.556zM1.11.421a2.511 2.511 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415L1.11.422zM16 2.5c0-.166-.016-.33-.048-.487l-.98.194c.018.094.028.192.028.293v.458h1V2.5zM.048 2.013A2.51 2.51 0 0 0 0 2.5v.458h1V2.5c0-.1.01-.199.029-.293l-.981-.194zM0 3.875v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 5.708v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 7.542v.916h1v-.916H0zm15 .916h1v-.916h-1v.916zM0 9.375v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .916v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .917v.458c0 .166.016.33.048.487l.98-.194A1.51 1.51 0 0 1 1 13.5v-.458H0zm16 .458v-.458h-1v.458c0 .1-.01.199-.029.293l.981.194c.032-.158.048-.32.048-.487zM.421 14.89c.183.272.417.506.69.689l.556-.831a1.51 1.51 0 0 1-.415-.415l-.83.556zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373c.158.032.32.048.487.048h.458v-1H2.5c-.1 0-.199-.01-.293-.029l-.194.981zM13.5 16c.166 0 .33-.016.487-.048l-.194-.98A1.51 1.51 0 0 1 13.5 15h-.458v1h.458zm-9.625 0h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zm1.834-1v1h.916v-1h-.916zm1.833 1h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z' />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                        <div className='exp-range-slider'>
                          {currentAddedSkill &&
                            currentAddedSkill.map((data, i) => (
                              <div key={i} className='skill-range-slider'>
                                <div className='slidecontainer'>
                                  <h6>
                                    <button 
                                      className="btn btn-danger" 
                                      onClick={() => {
                                        const skills = [...currentAddedSkill];
                                        skills.splice(i, 1);
                                        setCurrentAddedSkill(skills);
                                      }}
                                    >
                                      <i className="bi bi-trash-fill"></i> X
                                    </button>
                                    &nbsp; {data.option}
                                  </h6>
                                  <input
  onChange={e => getValue(data.option, Number(e.target.value))}
  value={data.scrollValue ?? 0}
  type='range' 
  min='0' 
  max='12' 
  step='4' 
  className='slider form-range' 
  id={`customRange2-${i}`}
/>
                                  <p>
                                    {data.level} ({data.experience}&nbsp;years)
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <input value='' onClick={savingInfo} type='button' name='next' className='next action-button' style={{ marginTop: '-8%' }} />
                    </fieldset>
                  </form>
                </div>
              </div>
              <div className='col-lg-2' />
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default SkillTwo;
