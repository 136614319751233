import React, { useState, useEffect } from "react";
import brand from "../../../../assets/images/logo-icon-blk.png";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  postApi,
  postApiPoster,
} from "../../../../utils";

import { toast } from "react-toastify";
import { Modal, ModalBody } from "react-bootstrap";

function SkillLevelUpdate({
  prevSkillLevel,
  skillLevelModal,
  setSkillLevelModal,
  updateProfileFunc,
}) {
  const navigation = useNavigate();
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const [routerData] = useState(state);
  const [skillLevel, setSkillLevel] = useState([]);
  const [isFetchedData, setFetchedData] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [skillLevelListed, setSkillLevelListed] = useState([]);
  const [jobRolesForSuggestions, setjobRolesForSuggestions] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [userCreatedRole, setUserCreatedRole] = useState("");
  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );
  const [skillSelectedForInput, setskillSelectedForInput] = useState('');

  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState('');
  const [suggestionOff, setSuggestionOff] = useState(false);
  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: "skilllevel",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = (response) => {
    console.log("get skill response-----", response?.data);
    console.log("get skill response-----", response?.data?.result[0]?.question);
    if (response.status === 200) {
      console.log("get seeker value", response?.data?.result[0]?.question);
      setJobTitle(response?.data?.result[0]?.question);
    }
  };
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error, "drerer");
  };
  const successFunctionOptions = (response) => {
    setLoading(false);
    setFetchedData(true);
    if (response.status === 200) {
      const adminData = response?.data?.result.filter(item => {
        return item.addedby === '3';
      });
      console.log('get filter data -----', adminData);
      setjobRolesForSuggestions(adminData);
      if (adminData.length > 9) {
        adminData.length = 9;
      }

      setSkillLevelListed(adminData);
    }

    // console.log(response);
  };
  const errorFunctionOptions = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };
  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = () => {
    if (skillLevel.length > 0) {
      const [id] = skillLevel;
      console.log("get skill valur", skillLevel);
      // navigation("../location", {
      //   state: { ...routerData, skillLevelId: skillLevel.toString() },
      // });
      updateProfileFunc("skillLevelId", id);
      setSkillLevelModal(false);
    } else if (userCreatedRole) {
      //*************************User created job manually*****************************/
      //********************************On loader when user saves data******************************
      setLoading(true);
      //*******Save info Api
      if (userType.payload === "jobSeeker") {
        const obj = {
          option: userCreatedRole,
          category: "educationlevel",
          usertype: "1",
          status: "2",
          userId: userId,
          addedby: "1",
        };
        postApi(
          API_SEEKER_ADD_JOB_ROLE,
          obj,
          userToken,
          saveDataSuccessFunction,
          errorSavingData
        );
      } else {
        const obj = {
          option: userCreatedRole,
          category: "educationlevel",
          usertype: "2",
          status: "2",
          userId: userId,
          addedby: "2",
        };
        postApiPoster(
          API_SEEKER_ADD_JOB_ROLE,
          obj,
          userToken,
          saveDataSuccessFunction,
          errorSavingData
        );
      }
    } else {
      toast.error("Please choose any of the option or type manually!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  //If data is saved suceessfully
  const saveDataSuccessFunction = (res) => {
    console.log("get new value--------", res);
    setLoading(false);
    if (res.status === 200) {
      navigation("../location", {
        state: { ...routerData, skillLevelId: res?.data?.data?._id },
      });
    }
  };
  //!error occured in saving data
  const errorSavingData = (err) => {
    toast.error(err.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Modal show={skillLevelModal}>
      <ModalBody>
        <button
          className="position-absolute"
          style={{
            width: "100px",
            backgroundColor: "transparent",
            top: "20px",
            left: "0px",
            fontSize: "30px",
            zIndex: "333",
            border: "none",
          }}
          onClick={() => setSkillLevelModal(false)}
        >
          <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
        </button>
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center LoaderCenter">
            <div className="block">
              <span
                className="spinner-grow spinner-grow-lg mx-2"
                role="status"
                aria-hidden="true"
              />
              <h6 className="LoaderText">Processing...</h6>
            </div>
          </div>
        )}
        {!isLoading && (
          <section id="job-role-steps">
            <div className="discover-personality-top-cnt">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" />
                  </div>
                </div>
              </div>
            </div>
            {/* <!--discover-personality-top-cnt--> */}

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-2" />
                <div className="col-lg-12">
                  <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                      {/* <!-- progressbar --> */}

                      <fieldset>
                        <div className="form-card">
                          {/* {userType.payload === 'jobSeeker' ? (
													jobTitle &&
													jobTitle.map((data) => <h3 key={data._id}>{data.question}</h3>)
												) : (
													jobTitle &&
													jobTitle.map((data) => <h3 key={data._id}>{data.question}</h3>)
												)} */}
                          <h3>{jobTitle}</h3>
                          <div className="role-wrapper">
                            {skillLevelListed &&
                              skillLevelListed.map((data, i) => (
                                <div key={i} className="role-col">
                                  <div key={(i = 1)} className="inputGroup">
                                    <input
                                      key={i + 2}
                                      id="radio37"
                                      name="radio"
                                      type="radio"
                                      readOnly
                                      checked={
                                        skillLevel.length > 0
                                          ? skillLevel.includes(data._id)
                                          : data.option === prevSkillLevel
                                      }
                                    />
                                    <label
                                      key={i + 3}
                                      onClick={() => setSkillLevel([data._id])}
                                      htmlFor="radio37"
                                    >
                                      {data.option}
                                    </label>
                                  </div>
                                </div>
                              ))}

                            {/* <!--role-col--> */}
                          </div>
                          {/* <!--role-wrapper--> */}

                          <div className="enter-job-role">
                            {userType.payload === "jobSeeker" ? (
                              <h5>Don’t see the level you're looking for?</h5>
                            ) : (
                              <h5>Don’t see the level you're hiring for?</h5>
                            )}

                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Type your job level here..."
                              required=""
                              value={skillSelectedForInput}
                              onFocus={event => {
                                event.target.setAttribute('autocomplete', 'off');
                                console.log(event.target.autocomplete);
                              }}
                              onChange={e => {
                                setSkillLevel([]);
                                setUserCreatedRole(e.target.value);
                                setskillSelectedForInput(e.target.value);
                                if (e.target.value === '') {
                                  setSuggestionOff(false);
                                  setSelectedFromSuggestion('');
                                }
                              }}
                            />
                          </div>
                        </div>
                        {/* <!--form-card--> */}
                        {userCreatedRole &&
                          !suggestionOff &&
                          !selectedFromSuggestion &&
                          jobRolesForSuggestions?.filter(skill =>
                            skill.option.toLowerCase().includes(userCreatedRole.toLowerCase())
                          ).length > 0 && (
                            <div className='position-absolute  jobs-dropdown-update'>
                              {jobRolesForSuggestions
                                ?.filter(skill =>
                                  skill.option
                                    .toLowerCase()
                                    .includes(userCreatedRole.toLowerCase())
                                )
                                ?.slice(0, 5)
                                ?.map(skill => (
                                  <div
                                    style={{
                                      padding: '5px 0',
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setUserCreatedRole(skill.option);
                                      setSelectedFromSuggestion(skill.option);
                                      setSuggestionOff(true);
                                      setSkillLevel([skill._id]);
                                      setskillSelectedForInput('');
                                      // setJobRoleSelected([skill._id])
                                    }}
                                  >
                                    {skill.option}
                                  </div>
                                ))}
                            </div>
                          )}
                        <input
                          type="button"
                          name="next"
                          className="form-btn mt-4"
                          value="update"
                          onClick={savingInfo}
                        />
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
          </section>
        )}
      </ModalBody>
    </Modal>
  );
}

export default SkillLevelUpdate;
