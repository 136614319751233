import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  API_JOBSEEKER_MATCH,
  postApi,
  postApiPoster,
  postApiPosterWithoutHeader,
} from "../../utils";
// import { Col, Nav, Row, Tab } from 'react-bootstrap';
export default function SideMenu({ onTouch }) {
  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );
  const [isLoading, setLoading] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("Current Matches");
  const [profileMatching, setProfileMatching] = useState([]);
  const [showBtns, setShowBtns] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const fistVisit = JSON.parse(sessionStorage.getItem("showPerspectiveBtn"));

  const sideMatchingArray = [
    { title: "Current Matches", color: "#1ec991", type: "CM" },
    { title: "Interested", color: "#5e43f8", type: "IN" },
    { title: "Mutual Interest", color: "#ffdb59", type: "MI" },
    { title: "Short Listed", color: "#d9453d", type: "SL" },
  ];
  useEffect(() => {
    requestDataFromServer(sideMatchingArray[0]);
  }, []);

  const requestDataFromServer = (item) => {
    setSelectedProfile(item.title);
    setLoading(true);

    if (userType.payload === "jobSeeker") {
      const obj = {
        id: userId,
        type: "1",
        match: item.type,
      };
      postApi(
        API_JOBSEEKER_MATCH,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
    } else {
      const obj = {
        id: userId,
        type: "2",
        match: item.type,
      };
      postApi(
        API_JOBSEEKER_MATCH,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
    }
  };

  const successFunction = (response) => {
    setLoading(false);
    // console.log("get user data------", response);
    if (response.status === 200) {
      setProfileMatching(response.data.data);
    }
  };

  const errorFunction = (error) => {
    setLoading(false);
    // console.log("get Error response", error);
  };
  const getInfo = (item) => {
    sessionStorage.setItem("showPerspectiveBtn", true);

    if (item.type === "CM") {
      // navigation('../chooseIndustry', { state: { ...routerData, jobTitleId: customId } });
      sessionStorage.removeItem("sidemenuselection");
      sessionStorage.setItem("sidemenuselection", "0");
      navigation("../mainMenu", { state: { data: profileMatching } });
    } else if (item.type === "IN") {
      sessionStorage.removeItem("sidemenuselection");
      sessionStorage.setItem("sidemenuselection", "1");
      navigation("../mainMenu", { state: { data: profileMatching } });
    } else if (item.type === "MI") {
      sessionStorage.removeItem("sidemenuselection");
      sessionStorage.setItem("sidemenuselection", "2");
      navigation("../mainMenu", { state: { data: profileMatching } });
    } else if (item.type === "SL") {
      sessionStorage.removeItem("sidemenuselection");
      sessionStorage.setItem("sidemenuselection", "3");
      navigation("../mainMenu", { state: { data: profileMatching } });
    }
  };

  const getNevigate = () => {
    navigation("../jobPoster/companies");
  };

  const navigation = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.matchMedia]);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setIsSmallScreen(true);
      if (fistVisit) {
        setShowBtns(false);
      }
    }
  }, []);

  if (window.performance) {
    if (performance.navigation.type == 1) {
      sessionStorage.removeItem("showPerspectiveBtn");
    } else {
      console.log("This page is not reloaded");
    }
  }

  const styles = {
    btnStyle: {
      transform: "scale(0.8)",
      marginTop: "-1%",
    },
  };

  const handleCurrentJobsBtn = () => {
    getNevigate();
    if (isSmallScreen) {
      setShowBtns((prevState) => !prevState);
    }
  };

  return (
    <div className="col-lg-3 col-sm-4 col-xs-12 dashboard-sidebar">
      <div className="row" style={{ paddingRight: 40 }}>
        {/*   {userType.payload === 'jobPoster' ? (
          <button
            className='matching-button'
            style={{ background: '#00C5C6', ...styles?.btnStyle }}
            onClick={handleCurrentJobsBtn}
          >
            Current Jobs
          </button>
        ) : (
          <span></span>
        )} */}
        <br />

        {showBtns &&
          sideMatchingArray.map((item, index) => (
            <button onClick={() => getInfo(item)} key={index} className="matching-button"
              style={{background: "#1ec991", backgroundColor: item.color, ...styles?.btnStyle}} >
              {item.title}
            </button>
          ))}
      </div>
    </div>
  );
}
