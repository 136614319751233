import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import brand from '../../../assets/images/logo-icon-blk.png';
import ArrowIcon from '../../../assets/images/right-arrow.png';
import congratsIcon from '../../../assets/images/congrats-img.png';
import PopUpimage from '../../../assets/images/login-popup-img-3.png';
import { useSelector } from 'react-redux';

function CompanyProfile() {
  const {state} = useLocation();
  const jobId = state?.jobId;
  const navigation = useNavigate();
  const userType = useSelector(state => state.userType.userType);
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const getUserInfoAndRedirect = () => {
    if (userType.payload === 'jobSeeker') {
      navigation('../mainMenu');
    } else if (userType.payload === 'jobPoster') {
      navigation('../currentJob');
    }
  };
  return (
    <React.Fragment>
      {userType.payload === 'jobSeeker' && (
        <section id='congrts-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-2' />
              <div className='col-lg-8 text-center'>
                <div className='brand-logo'>
                  <img className='w-80px' src={brand} alt='brand' />
                </div>

                <h6 style={{ fontSize: '2rem' }}>Congrats! You’ve reached the end</h6>
                <p>
                  At this stage we have everything we need to start searching for your perfect job.
                  Everyday we continue to discover better ways to connect the right people to the
                  right roles. We’ll notify you as soon as we find the perfect match for you.
                </p>
                <br />
                <br />

                <img src={congratsIcon} alt='' />

                <div className='congrats-button text-center mt-5 add1'>
                  <img
                    src={ArrowIcon}
                    alt='right'
                    onClick={() => navigation('../boostProfile')}
                  />
                  <br />
                  <br />
                  <p>
                    {/* style="text-decoration: none; color:white;" */}
                    <a href='javascript:void(0)'
                      onClick={getUserInfoAndRedirect}
                      className='text-white text-decoration-none'>
                      Skip
                    </a>
                  </p>
                </div>
              </div>
              <div className='col-lg-2' />
            </div>
          </div>
        </section>
      )}
      {userType.payload === 'jobPoster' && (
        <section id='congrts-reacheds'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <div className='brand-logo'>
                  <img src={brand} />
                </div>

                <img className='img-fluid' src={PopUpimage} />

                <div className='perfect-content'>
                  <h3>Congrats! </h3>
                  <h5>You’ve reached the end</h5>
                  <p>
                    At this stage we have everything we need to start searching for your perfect
                    candidate. Everyday we continue to discover better ways to connect the right
                    people to the right roles. We’ll notify you as soon as we find the perfect match
                    for the job you’re offering.
                  </p>

                  <a href='javascript:void(0)' className='add1'>
                    <img src={ArrowIcon} onClick={() => navigation('../jobDetails', {state: {jobId: jobId}})} />{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
      }
    </React.Fragment >
  );
}

export default CompanyProfile;
