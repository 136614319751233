import React, { useState, useEffect } from "react";
import brand from "../../../../assets/images/logo-icon-blk.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  postApi,
  postApiPoster,
  API_ADD_LOCATION,
} from "../../../../utils";

import { toast } from "react-toastify";
import Autosuggestion from "../../locationAutoSuggestion/autosugestion";
import LocationSearch from "../../locationAutoSuggestion/autosugestion";
import { Modal, ModalBody } from "react-bootstrap";
// import Autosuggest from 'react-autosuggest';
function LocationUpdate({
  prevLocation,
  locationModal,
  setLocationModal,
  updateProfileFunc,
}) {
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const [routerData] = useState(state);
  const navigation = useNavigate();
  const [locationSelected, setLocationSelected] = useState([]);
  const [isRemoteWorker, setRemoteWorker] = useState(false);
  const [locationOption, setLocationOption] = useState([]);
  const [isFetchedData, setFetchedData] = useState(false);
  const [jobTitle, setJobTitle] = useState("");

  const [isLoading, setLoading] = useState(false);
  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );

  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: "location",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = (response) => {
    setFetchedData(true);
    setLoading(false);
    if (response.status === 200) {
      setJobTitle(response.data.result);
    }
  };
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
  };
  const successFunctionOptions = (response) => {
    console.log(response, "location");
    setLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      setLocationOption(response.data.result);
    }

    // console.log(response);
  };
  const errorFunctionOptions = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };
  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = () => {
    if (locationSelected.length > 0) {
      setLoading(true);
      const obj = {
        userId: userId,
        location: locationSelected,
        remote: isRemoteWorker,
      };
      postApi(
        API_ADD_LOCATION,
        obj,
        userToken,
        locationAddSuccess,
        failedLocationAdd
        );
    } else {
      toast.error("Oops! you forgot to select location!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const locationAddSuccess = (response) => {
    setLoading(false);
    console.log("response location data", response.data);
    if (response.status === 200) {
      const locationName = response.data.locationName;
      const remote = response.data.remote;
      setLocationModal(false);

      updateProfileFunc("locationName", locationName, "remotely", remote);
    }

    // console.log(response);
  };
  const failedLocationAdd = (error) => {
    setLoading(false);
    toast.error("Oops! something went wrong!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  //Auto suggestion value
  const getLocationSelected = (location) => {
    console.log(location, location);

    setLocationSelected(location);
  };
  useEffect(() => { }, [locationSelected]);

  return (
    <Modal show={locationModal}>
      <ModalBody>
        <button
          className="position-absolute"
          style={{
            width: "100px",
            backgroundColor: "transparent",
            top: "20px",
            left: "0px",
            fontSize: "30px",
            zIndex: "333",
            border: "none",
          }}
          onClick={() => setLocationModal(false)}
        >
          <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
        </button>
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center LoaderCenter">
            {" "}
            <div className="block">
              <span
                className="spinner-grow spinner-grow-lg mx-2"
                role="status"
                aria-hidden="true"
              />
              <h6 className="LoaderText">Processing...</h6>
            </div>
          </div>
        )}
        {!isLoading && (
          <section id="job-role-steps">
            <div className="discover-personality-top-cnt">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" />
                  </div>
                </div>
              </div>
            </div>
            {/* <!--discover-personality-top-cnt--> */}

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                      {/* <!-- progressbar --> */}

                      <fieldset>
                        <div className="form-card">
                          {userType.payload === "jobSeeker" ? (
                            <h5>Where would you like the job to be located?</h5>
                          ) : (
                            <h5>Where would you like the job to be located?</h5>
                          )}
                          {userType.payload === "jobSeeker"
                            ? jobTitle &&
                            jobTitle.map((data) => (
                              <h3 key={data._id}>{data.question}</h3>
                            ))
                            : jobTitle &&
                            jobTitle.map((data) => (
                              <h3 key={data._id}>{data.question}</h3>
                            ))}
                          <div className="enter-job-role">
                            <LocationSearch
                              onLocationSelected={getLocationSelected}
                            />
                            <label className="steps-checkbox">
                              <input
                                type="checkbox"
                                checked={isRemoteWorker}
                                onChange={(e) =>
                                  setRemoteWorker(!isRemoteWorker)
                                }
                              />
                              <span className="checkmark" />
                              I'm happy to work remotely.
                            </label>
                            {/* <!--steps-checkbox--> */}
                          </div>
                        </div>
                        {/* <!--form-card--> */}

                        <input
                          type="button"
                          name="next"
                          className="form-btn mt-4"
                          value="update"
                          onClick={savingInfo}
                        />
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </ModalBody>
    </Modal>
  );
}

export default LocationUpdate;
