import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SuggestionView from "../../../../components/atoms/suggestionList";
import {
  API_SEEKER_ADD_JOB_ROLE,
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  postApi,
  postApiPoster,
} from "../../../../utils";
import brand from "../../../../assets/images/logo-icon-blk.png";
import { useSelector } from "react-redux";
import axios from "axios";

const JobRoleUpdate = ({
  jobRole,
  jobRoleModal,
  setJobRoleModal,
  updateProfileFunc,
}) => {
  const navigation = useNavigate();

  const [jobRoleSelected, setJobRoleSelected] = useState([]);
  const [percentComplete, setPercentComplete] = useState(false);
  const [isFetchedData, setFetchedData] = useState(false);
  const [jobSeekerTitle, setJobSeekerTitle] = useState("");
  const [jobListed, setJobListed] = useState([]);
  const [jobSuggestionList, setJobSuggestionList] = useState([]);
  const [isJobRoleLoading, setJobRoleLoading] = useState(false);
  const [userCreatedRole, setUserCreatedRole] = useState("");
  const [jobRolesForSuggestions, setjobRolesForSuggestions] = useState([]);
  const [jobRoleForTextField, setjobRoleForTextField] = useState("");
  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState("");
  const [suggestionOff, setSuggestionOff] = useState(false);

  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );
  // console.log(userToken, 'user');
  // const navigation = useNavigate();
  //**************call api for gettting data from server********************************//
  const requestJobRoleData = () => {
    setJobRoleLoading(true);
    const obj = {
      category: "jobrole",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successJobRoleFunction,
        errorJobRoleFunction
      );
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successJobRoleFunction,
        errorJobRoleFunction
      );
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successJobRoleFunction = (response) => {
    setFetchedData(true);
    setJobRoleLoading(false);
    if (response.status === 200) {
      setJobSeekerTitle(response.data.result);
    }
  };
  const errorJobRoleFunction = (error) => {
    setJobRoleLoading(false);
    setFetchedData(true);
    console.log(error, "drerer");
  };
  const successFunctionOptions = (response) => {
    setJobRoleLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      var suggestion = [];
      var jobList = [];
      response?.data?.result.map((item) => {
        if (item.addedby === "3") {
          // eslint-disable-next-line no-unused-expressions
          jobList.push(item);
        } else {
          // eslint-disable-next-line no-unused-expressions
          suggestion.push(item);
        }
      });
      setjobRolesForSuggestions(jobList);

      if (jobList.length > 9) {
        jobList.length = 9;
      }
      setJobListed(jobList);
    }

    // console.log(response);
  };
  const errorFunctionOptions = (error) => {
    setJobRoleLoading(false);
    setFetchedData(true);
  };
  useEffect(() => {
    if (!isFetchedData) {
      requestJobRoleData();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = async (mode) => {
    let obj = {};
    if (userType.payload === "jobSeeker") {
      obj = {
        option: userCreatedRole,
        category: "jobrole",
        usertype: 1,
        status: "2",
        userId: userId,
        addedby: "1",
      };
    } else {
      obj = {
        option: userCreatedRole,
        category: "jobrole",
        usertype: 2,
        status: "2",
        userId: userId,
        addedby: "2",
      };
    }

    const axiosConfig = {
      headers: {
        type: "front-end",
        mode: mode == null ? 1 : mode,
        Authorization: "Bearer " + userToken,
      },
    };

    try {
      const data = await axios.post(API_SEEKER_ADD_JOB_ROLE, obj, axiosConfig);
      updateProfileFunc("roleId", data?.data?.data?._id);
    } catch (error) {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // if (jobRoleSelected.length > 0) {
    //   const [id] = jobRoleSelected;
    //   // navigation("../jobTitle", { state: { roleId: id } });
    //   updateProfileFunc("roleId", id);
    // } else if (userCreatedRole) {
    //   //*************************User created job manually*****************************/
    //   //********************************On loader when user saves data******************************
    //   setJobRoleLoading(true);
    //   //*******Save info Api
    //   if (userType.payload === "jobSeeker") {
    //     const obj = {
    //       option: userCreatedRole,
    //       category: "jobrole",
    //       usertype: 1,
    //       status: "2",
    //       userId: userId,
    //       addedby: "1",
    //     };
    //     console.log("get console0-------", obj);
    //     postApi(
    //       API_SEEKER_ADD_JOB_ROLE,
    //       obj,
    //       userToken,
    //       saveDataSuccessFunction,
    //       errorSavingData
    //     );
    //   } else {
    //     const obj = {
    //       option: userCreatedRole,
    //       category: "jobrole",
    //       usertype: 2,
    //       status: "2",
    //       userId: userId,
    //       addedby: "2",
    //     };
    //     console.log("get console0------->>>", obj);
    //     postApiPoster(
    //       API_SEEKER_ADD_JOB_ROLE,
    //       obj,
    //       userToken,
    //       saveDataSuccessFunction,
    //       errorSavingData
    //     );
    //   }
    // } else {
    //   toast.error("Please choose any of the option or type manually!", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  //If data is saved suceessfully
  const saveDataSuccessFunction = (res) => {
    console.log(res, "ress");
    setJobRoleLoading(false);
    if (res.status === 200) {
      const customId = res.data.data._id;
      // navigation("../jobTitle", { state: { roleId: customId } });
      updateProfileFunc("roleId", customId);
    }
  };
  //!error occured in saving data
  const errorSavingData = (err) => {
    toast.error(err.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    if (jobRoleSelected.length > 0) {
      setPercentComplete(true);
    }

    return () => {
      setPercentComplete(false);
    };
  }, [jobRoleSelected]);

  const pickSuggestionData = (item) => {
    console.log("get new data", item);
  };

  return (
    <div>
      <Modal show={jobRoleModal} style={{ width: "100%" }}>
        <ModalBody>
          <>
            <button
              className="position-absolute"
              style={{
                width: "100px",
                backgroundColor: "transparent",
                top: "20px",
                left: "0px",
                fontSize: "30px",
                zIndex: "333",
                border: "none",
              }}
              onClick={() => setJobRoleModal(false)}
            >
              <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
            </button>
            {isJobRoleLoading && (
              <div className="d-flex justify-content-center align-items-center LoaderCenter">
                {" "}
                <div className="block">
                  <span
                    className="spinner-grow spinner-grow-lg mx-2"
                    role="status"
                    aria-hidden="true"
                  />
                  <h6 className="LoaderText">Processing...</h6>
                </div>
              </div>
            )}
            {!isJobRoleLoading && (
              <section id="job-role-steps">
                <div className="discover-personality-top-cnt">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <img src={brand} alt="brand" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--discover-personality-top-cnt--> */}

                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-2" />
                    <div className="col-lg-12">
                      <div className="card" style={{ borderWidth: 0 }}>
                        <form id="msform">
                          <fieldset>
                            <div className="form-card">
                              {userType.payload === "jobSeeker"
                                ? jobSeekerTitle &&
                                  jobSeekerTitle.map((data) => (
                                    <h3 key={data._id}>{data.question}</h3>
                                  ))
                                : jobSeekerTitle &&
                                  jobSeekerTitle.map((data) => (
                                    <h3 key={data._id}>{data.question}</h3>
                                  ))}

                              <div className="role-wrapper">
                                {jobListed &&
                                  jobListed.map((data, i) => (
                                    <div key={i + 3} className="role-col">
                                      <div key={i} className="inputGroup">
                                        <input
                                          key={i + 1}
                                          id="radio1"
                                          name="radio"
                                          type="radio"
                                          // readOnly
                                          checked={
                                            jobRoleSelected.length > 0
                                              ? jobRoleSelected.includes(
                                                  data._id
                                                )
                                              : jobRole === data.option
                                          }
                                        />
                                        <label
                                          key={i + 2}
                                          htmlFor="radio1"
                                          onClick={() => {
                                            setjobRoleForTextField("");
                                            setJobRoleSelected([data._id]);
                                            setUserCreatedRole(data.option);
                                          }}
                                        >
                                          {data.option}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div className="enter-job-role">
                                {userType.payload === "jobSeeker" ? (
                                  <h5>
                                    Don’t see the Culture you looking for?
                                  </h5>
                                ) : (
                                  <h5>Don’t see the Culture you hiring for?</h5>
                                )}
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Type your Culture here..."
                                  value={jobRoleForTextField}
                                  onFocus={(event) => {
                                    event.target.setAttribute(
                                      "autocomplete",
                                      "off"
                                    );
                                    console.log(event.target.autocomplete);
                                  }}
                                  onChange={(e) => {
                                    setjobRoleForTextField(e.target.value);
                                    setUserCreatedRole(e.target.value);
                                    setJobRoleSelected([]);
                                    if (e.target.value === "") {
                                      setSuggestionOff(false);
                                      setSelectedFromSuggestion("");
                                    }
                                  }}
                                />
                                {/* {userCreatedRole &&
                                  !suggestionOff &&
                                  !selectedFromSuggestion &&
                                  jobRolesForSuggestions?.filter(skill =>
                                    skill.option.toLowerCase().includes(userCreatedRole.toLowerCase())
                                  ).length > 0 && (
                                    <div className='position-absolute jobs-dropdown-update'>
                                      {jobRolesForSuggestions
                                        ?.filter(skill =>
                                          skill.option
                                            .toLowerCase()
                                            .includes(userCreatedRole.toLowerCase())
                                        )
                                        ?.slice(0, 5)
                                        ?.map(skill => (
                                          <div
                                            style={{
                                              padding: '5px 0',
                                              borderRadius: '5px',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              setUserCreatedRole(skill.option);
                                              setSelectedFromSuggestion(skill.option);
                                              setSuggestionOff(true);
                                              setJobRoleSelected([skill._id]);
                                              setjobRoleForTextField('')
                                            }}
                                          >
                                            {skill.option}
                                          </div>
                                        ))}
                                    </div>
                                  )} */}
                              </div>
                            </div>

                            <input
                              type="button"
                              name="next"
                              className="form-btn mt-4"
                              value="update"
                              onClick={() => {
                                savingInfo();
                                setJobRoleModal(false);
                              }}
                            />
                          </fieldset>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                </div>
              </section>
            )}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default JobRoleUpdate;
