import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import brand from "../../../../assets/images/logo-icon-blk.png";
import { useSelector } from "react-redux";
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  postApi,
  postApiPoster,
  getApi,
  SOFT_AND_HARD_SKILLS_LIST,
} from "../../../../utils";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "react-bootstrap";

function SoftSkillUpdate1({
  prevSoftSkill,
  soft1Modal,
  setSoft1Modal,
  updateProfileFunc,
}) {
  const { state } = useLocation();
  const [routerData] = useState(state);
  const [curtiousValue, setcurtiousValue] = useState(1);

  const [softSkillsList, setSoftSkillsList] = useState([]);
  const [dataAdded, setDataAdded] = useState([]);
  const [jobSeekerTitle, setJobSeekerTitle] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [isFetchedData, setFetchedData] = useState(false);
  const navigation = useNavigate();
  const [userCreatedRole, setUserCreatedRole] = useState("");
  const [addedSkill, setAddedSkill] = useState([]);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userType = useSelector((state) => state.userType.userType);
  const [isAddButton, setAddButton] = useState(false);
  const [currentAddedSkill, setCurrentAddedSkill] = useState(
    prevSoftSkill ? prevSoftSkill.map((s) => s.skill) : []
  );

  console.log("currentAddedSkill", currentAddedSkill);
  //********************************************call api*************************************************************//
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: "softskill",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = (response) => {
    setFetchedData(true);
    setLoading(false);
    if (response.status === 200) {
      console.log(response.data.result, "title");
      setJobSeekerTitle(response.data.result.question);
    }
  };
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error, "drerer");
  };
  const successFunctionOptions = (response) => {
    console.log(response, "companySize");
    setLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      let dataWithExperienceValue = [];
      let dataComingIntial = response.data.result;
      dataComingIntial.map((data) =>
        dataWithExperienceValue.push({
          _id: data._id,
          option: data.option,
          experience: 0,
        })
      );
      setSoftSkillsList(dataWithExperienceValue);
    }

    // console.log(response);
  };
  const errorFunctionOptions = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };
  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = () => {
    if (currentAddedSkill.length > 0) {
      if (currentAddedSkill.length > 0) {
        const skill = [];
        currentAddedSkill.map((data) =>
          skill.push({
            id: data._id,
            skill: {
              explevel: data.level || data.explevel,
              option: data.option,
              exp: data.experience
                ? data.experience + " years"
                : data.exp?.split(" ")[0] + " years",
            },
          })
        );
        // console.log('get iamge-----', skill);
        // navigation("../roleStepCompleted", {
        //   state: { ...routerData, softSkill: skill },
        // });

        setSoft1Modal(false);
        updateProfileFunc("softSkill", skill);
      }
    } else {
      toast.error("Please add skill!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //*************************Slider value  change function****************
  // !Where there is a will there is a way********Jaspa
  const getValue = (data, value) => {
    //Update with new value
    var index = currentAddedSkill.findIndex((item) => item.option === data);
    currentAddedSkill.map((el) => {
      if (el.option == data) {
        if (value <= 2) {
          currentAddedSkill[index].level = "Entry";
          const userValue = 2;
          const range = "0-";
          const append = range.concat(userValue);

          currentAddedSkill[index].experience = append;
          currentAddedSkill[index].scrollValue = 2;
        } else if (value > 2 && value < 5) {
          currentAddedSkill[index].level = "Mid";
          const userValue = 5;
          const range = "3-";
          const append = range.concat(userValue);
          currentAddedSkill[index].experience = append;
          currentAddedSkill[index].scrollValue = 6;
        } else if (value > 5 && value < 9) {
          currentAddedSkill[index].level = "Senior";
          const userValue = 9;
          const range = "6-";
          const append = range.concat(userValue);
          currentAddedSkill[index].experience = append;
          currentAddedSkill[index].scrollValue = 9;
        } else if (value > 10) {
          currentAddedSkill[index].level = "Expert";
          currentAddedSkill[index].experience = "10+";
          currentAddedSkill[index].scrollValue = 12;
        }
        setCurrentAddedSkill([...currentAddedSkill]);
      }
    });
  };
  useEffect(() => { }, [currentAddedSkill]);
  useMemo(() => {
    if (userCreatedRole !== "") {
      const userSearch = userCreatedRole.toLowerCase();
      const data = softSkillsList.filter(
        (data) => data.option.toLowerCase() === userSearch
      );
      // setUserCreatedRole('');
      if (data.length === 0) {
        setAddButton(true);
      } else {
        setAddButton(false);
        let check = currentAddedSkill.filter(
          (skill) => skill.option === data.map((el) => el.option)
        );
        if (check.length > 0) {
          toast.error("This skill is already in list!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setCurrentAddedSkill([...currentAddedSkill, ...data]);
        }
      }
    }
  }, [userCreatedRole]);
  //***********************************Add new skills*****************************************************///
  const addNewSkill = (e) => {
    e.preventDefault();
    if (userCreatedRole) {
      setLoading(true);
      if (userType.payload === "jobSeeker") {
        const obj = {
          option: userCreatedRole,
          category: "softskill",
          usertype: 1,
          status: "2",
          userId: userType.payload._id,
          addedby: "2",
        };
        postApi(
          API_SEEKER_ADD_JOB_ROLE,
          obj,
          userToken,
          addHardSkillSuccess,
          addHardSkillError
        );
      } else {
        const obj = {
          option: userCreatedRole,
          category: "softskill",
          usertype: 2,
          status: "2",
          userId: userType.payload._id,
          addedby: "2",
        };
        postApi(
          API_SEEKER_ADD_JOB_ROLE,
          obj,
          userToken,
          addHardSkillSuccess,
          addHardSkillError
        );
      }
    }
  };
  const addHardSkillSuccess = (res) => {
    if (res.status === 200) {
      setLoading(false);

      toast.success("Your skill added succefully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const userValue = 2;
      const range = "0-";
      const append = range.concat(userValue);
      const data = {
        addedby: "3",
        category: "hardskill",
        name: "Unknown",
        option: userCreatedRole,
        status: 1,
        usertype: "1",
        _id: res.data.data._id,
        level: "Entry",
        experience: append,
        scrollValue: 0,
      };
      setCurrentAddedSkill([...currentAddedSkill, data]);
      setUserCreatedRole("");
      setAddButton(false);

      setSoftSkillsList([...softSkillsList, data]);
    }
  };
  const addHardSkillError = (err) => {
    setLoading(false);
    toast.error(err.response.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  console.log(softSkillsList, "option");

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + userToken,
    },
  };

  const successCallback = (data) => {
    console.log("DATA++++++", data.data);
    setHardSkillLists(data?.data?.hardskills);
    setSoftSkillLists(data?.data?.softskills);
  };
  const errorCallback = (error) => {
    console.log("error-------", error);
  };

  const [hardSkillLists, setHardSkillLists] = useState([]);
  const [softSkillLists, setSoftSkillLists] = useState([]);
  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState("");
  const [suggestionOff, setSuggestionOff] = useState(false);

  useEffect(() => {
    getApi(
      SOFT_AND_HARD_SKILLS_LIST,
      axiosConfig,
      successCallback,
      errorCallback
    );
  }, []);

  useEffect(() => {
    if (userCreatedRole) {
      setSelectedFromSuggestion("");
    }

    // if (userCreatedRole !== selectedFromSuggestion) {
    //   setSuggestionOff(false);
    // }
  }, [userCreatedRole, selectedFromSuggestion]);
  return (
    <React.Fragment>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center LoaderCenter">
          {" "}
          <div className="block">
            <span
              className="spinner-grow spinner-grow-lg mx-2"
              role="status"
              aria-hidden="true"
            />
            <h6 className="LoaderText">Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <Modal show={soft1Modal}>
          <ModalBody>
            <button
              className="position-absolute"
              style={{
                width: "100px",
                backgroundColor: "transparent",
                top: "20px",
                left: "0px",
                fontSize: "30px",
                zIndex: "333",
                border: "none",
              }}
              onClick={() => setSoft1Modal(false)}
            >
              <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
            </button>
            <section id="soft-skills-steps">
              <div className="discover-personality-top-cnt">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <img src={brand} alt="brand" />
                      <h3>
                        Add up to 10 soft skills and rate your proficiency in them.

                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="card" style={{ borderWidth: 0 }}>
                      <form id="msform" onSubmit={addNewSkill}>
                        <fieldset>
                          <div className="form-card">
                            <div className="enter-hard-skills">
                              <h5>
                                <strong>Soft skills</strong> are the personal
                                habits and traits that shape how you work on
                                your own and with others.
                              </h5>
                              <div
                                className="d-flex"
                                style={{ position: "relative" }}
                              >
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Type your soft skills here..."
                                  required=""
                                  value={userCreatedRole}
                                  onChange={(e) =>
                                    setUserCreatedRole(e.target.value)
                                  }
                                />

                                {userCreatedRole &&
                                  !suggestionOff &&
                                  !selectedFromSuggestion &&
                                  softSkillLists?.filter((skill) =>
                                    skill
                                      .toLowerCase()
                                      .includes(userCreatedRole.toLowerCase())
                                  ).length > 0 && (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        top: "60px",
                                        border: "1px solid #4344f8",
                                        width: "100%",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        backgroundColor: "white",
                                        zIndex: "9999",
                                      }}
                                    >
                                      {softSkillLists
                                        ?.filter((skill) =>
                                          skill
                                            .toLowerCase()
                                            .includes(
                                              userCreatedRole.toLowerCase()
                                            )
                                        )
                                        ?.slice(0, 5)
                                        ?.map((skill) => (
                                          <div
                                            style={{
                                              padding: "5px 0",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setUserCreatedRole(skill);
                                              setSelectedFromSuggestion(skill);
                                              setSuggestionOff(true);
                                            }}
                                          >
                                            {skill}
                                          </div>
                                        ))}
                                    </div>
                                  )}

                                {isAddButton && (
                                  <button
                                    className="mx-1 btn btn-outline-primary"
                                    type="submit"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-plus-square-dotted"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2.5 0c-.166 0-.33.016-.487.048l.194.98A1.51 1.51 0 0 1 2.5 1h.458V0H2.5zm2.292 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zm1.833 0h-.916v1h.916V0zm1.834 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zM13.5 0h-.458v1h.458c.1 0 .199.01.293.029l.194-.981A2.51 2.51 0 0 0 13.5 0zm2.079 1.11a2.511 2.511 0 0 0-.69-.689l-.556.831c.164.11.305.251.415.415l.83-.556zM1.11.421a2.511 2.511 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415L1.11.422zM16 2.5c0-.166-.016-.33-.048-.487l-.98.194c.018.094.028.192.028.293v.458h1V2.5zM.048 2.013A2.51 2.51 0 0 0 0 2.5v.458h1V2.5c0-.1.01-.199.029-.293l-.981-.194zM0 3.875v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 5.708v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 7.542v.916h1v-.916H0zm15 .916h1v-.916h-1v.916zM0 9.375v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .916v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .917v.458c0 .166.016.33.048.487l.98-.194A1.51 1.51 0 0 1 1 13.5v-.458H0zm16 .458v-.458h-1v.458c0 .1-.01.199-.029.293l.981.194c.032-.158.048-.32.048-.487zM.421 14.89c.183.272.417.506.69.689l.556-.831a1.51 1.51 0 0 1-.415-.415l-.83.556zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373c.158.032.32.048.487.048h.458v-1H2.5c-.1 0-.199-.01-.293-.029l-.194.981zM13.5 16c.166 0 .33-.016.487-.048l-.194-.98A1.51 1.51 0 0 1 13.5 15h-.458v1h.458zm-9.625 0h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zm1.834-1v1h.916v-1h-.916zm1.833 1h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </div>

                            <div className="exp-range-slider">
                              {currentAddedSkill &&
                                currentAddedSkill.map((data, i) => (
                                  <div key={i} className="skill-range-slider">
                                    <div key={i + 1} className="slidecontainer">
                                      <h6 key={i + 2}>{data.option}</h6>
                                      <input
                                        key={i + 3}
                                        type="range"
                                        min="0"
                                        max="12"
                                        step={4}
                                        defaultValue={
                                          data.scrollValue
                                            ? data.scrollValue
                                            : data?.exp?.split("-")[0] == 0
                                              ? 0
                                              : data?.exp?.split("-")[0] == 3
                                                ? 4
                                                : data?.exp?.split("-")[0] == 6
                                                  ? 8
                                                  : data?.exp?.split("+")[0] == 10
                                                    ? 12
                                                    : 0
                                        }
                                        onChange={(e) => {
                                          getValue(data.option, e.target.value);
                                        }}
                                        className="slider form-range"
                                        id="customRange2"
                                      />
                                      <p key={i + 4}>
                                        {data.level || data.explevel} (
                                        <span key={i + 5} id="demo" />
                                        {data.experience ||
                                          data?.exp?.split(" ")[0]}
                                        &nbsp;years)
                                      </p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <input
                            type="button"
                            name="next"
                            className="form-btn mt-4"
                            value="update"
                            onClick={savingInfo}
                          />
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default SoftSkillUpdate1;
