import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import brand from '../../../assets/images/logo-icon-blk.png';
import ArrowIcon from '../../../assets/images/right-arrow.png';
import circlePlus from '../../../assets/images/circle-plus.svg';
import creditcard from '../../../assets/images/credit-card.svg';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  API_PAYMENT_GET_CARDS,
  API_PAYMENT_GET_CARDS_AND_PAYMENT_HISTORY,
  API_PAYMENT_INTENT_SECRET,
  getApi,
  postApi,
} from '../../../utils';
import { PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { userType } from '../../../redux/actions';

function PaymentMethod() {
  const navigation = useNavigate();
  const [cardInformation, setCardInformation] = useState([]);
  const [paymentHistory, setpaymentHistory] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [planInfo, setPlan] = useState();
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const [payAsYouGoData, setpayAsYouGoData] = useState({});
  const location = useLocation();
  const [payAsyouGoSelected, setpayAsyouGoSelected] = useState(false);

  const usertype = useSelector(state => state.userType.userType);

  const [justForUseEffect, setjustForUseEffect] = useState(false);

  useEffect(() => {
    if (location?.state?.planInfo !== undefined) {
      localStorage.setItem('PlanInfo', JSON.stringify(location?.state?.planInfo));
    }

    fetchCardInformation();

    const isPlan = location?.state?.planInfo || {};
    if (location?.state?.payAsYouGo) {
      setpayAsyouGoSelected(true);
      localStorage.setItem('PayAsYouGo', JSON.stringify(location?.state?.payAsYouGo));
    }

    const payAsYouGo = location?.state?.payAsYouGo || {};
    setpayAsYouGoData(payAsYouGo);
    setPlan(isPlan);
    setjustForUseEffect(true);

  }, []);

  useEffect(() => {
    var playInfoData = JSON.parse(localStorage.getItem('PlanInfo'));
    var payAsyouGoInfo = JSON.parse(localStorage.getItem('PayAsYouGo'));
    // console.log("------- plan info data ------", playInfoData);
    // console.log("------- Pay As you go data ------", payAsyouGoInfo);
    setPlan(playInfoData);
    setpayAsYouGoData(payAsyouGoInfo);

  }, [justForUseEffect])



  const navigateToPaymentGateway = cardInfo => {
    console.log("navigating to payment gateway", planInfo, "------payasyougo", payAsYouGoData)

    if (cardInfo) {
      if (payAsyouGoSelected === true) {
        navigation('../paymentGatewayCard', {
          state: {
            cardInfo,
            payAsYouGoData,
          },
        });
      } else {
        navigation('../paymentGatewayCard', {
          state: {
            cardInfo,
            planInfo,
          },
        });
      }
    } else {
      if (payAsyouGoSelected) {
        navigation('../paymentGatewayCard', { state: { payAsYouGoData } });
      } else {
        navigation('../paymentGatewayCard', { state: { planInfo } });
      }
    }
  };

  const fetchCardInformation = async () => {
    setisLoading(true);
    let axiosConfig = {
      headers: {
        type: 'front-end',
        mode: 1,
        Authorization: 'Bearer ' + userToken,
      },
    };
    getApi(API_PAYMENT_GET_CARDS_AND_PAYMENT_HISTORY, axiosConfig, onSuccess, onFailure);
  };

  const onSuccess = ({ data }) => {
    setCardInformation(data?.cards || []);
    setpaymentHistory(data?.payHistory || []);
    setisLoading(false);
  };

  const onFailure = err => {
    console.log(err, 'sorry error ocured');
    setisLoading(false);
  };

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center LoaderCenter'>
        <div className='block'>
          <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
          <h6 className='LoaderText'>Processing...</h6>
        </div>
      </div>
    );
  }

  return (
    <section id='red-graphics-bg' className='upload-your-video'>
      <div className='container'>
        <div className='row d-flex algin-items-center justify-content-center'>
          <div className='col-lg-9 text-center'>
            <img src={brand} alt='brand' className='w-80px' />
            <h3 style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>Payment</h3>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-4 col-md-3 col-sm-2' />
          <div className='col-lg-4 col-md-6 col-sm-8 text-center'>
            <div className='form-group add-payment-method'>
              <div className='accordion' id='accordionExample'>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Payment Method
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <ul>
                        {cardInformation.map(item => {
                          return (
                            <li key={item.id}>
                              <a onClick={() => navigateToPaymentGateway(item)}>
                                <div>
                                  {item.card.brand.toUpperCase()} **** **** **** {item.card.last4}
                                  <div>
                                    EXP {item.card.exp_month}/{item.card.exp_year}
                                  </div>
                                </div>
                                <img src={creditcard} alt='' />
                              </a>
                            </li>
                          );
                        })}
                        <li>
                          <a onClick={() => navigateToPaymentGateway()}>
                            Add New Card
                            <img src={circlePlus} alt='' />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      Payment History
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <ul>
                        {paymentHistory.length === 0 ? (
                          <li>
                            <a href='javascript:void(0)'>
                              <span>No Transaction</span>
                            </a>
                          </li>
                        ) : (
                          <RenderPaymenthistory
                            userType={usertype.payload}
                            paymentHistory={paymentHistory}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                      Payment Details
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <ul>
                        <li>
                          <a href=''>
                            <span>£35 Free for enhanced Profile Video</span>{' '}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            {/* <p>
              <a href="" onClick={() => navigation("../paymentGatewayCard")}>
                <img src={ArrowIcon} alt="right" />
              </a>
            </p> */}
          </div>

          <div className='col-lg-4 col-md-3 col-sm-2' />
        </div>
      </div>
    </section>
  );
}

const RenderPaymenthistory = ({ paymentHistory, userType }) => {
  if (userType === 'jobSeeker') {
    return (
      <>
        <li>
          <a href='javascript:void(0)'>
            <span>Profile</span>
            <span>Amount</span>
            <span>Date</span>
          </a>
        </li>
        {paymentHistory?.map(item => {
          console.log(item);
          const d = new Date(item.createdAt);
          const formatedDate = `${d.getDate()}/${('0' + d.getMonth()).slice(
            -2
          )}/${d.getFullYear()}`;
          console.log(d);
          return (
            <li key={item.key}>
              <a href='javascript:void(0)'>
                <span>{'Enhanced Profile'}</span>
                <span>{item.amount}</span>
                <span>{formatedDate}</span>
              </a>
            </li>
          );
        })}
      </>
    );
  } else {
    return (
      <>
        <li>
          <a href='javascript:void(0)'>
            <span>Plan</span>
            <span>Amount</span>
            <span>Credits</span>
            <span>Date</span>
          </a>
        </li>
        {paymentHistory?.map(item => {
          const d = new Date(item.createdAt);
          const formatedDate = `${d.getDate()}/${('0' + d.getMonth()).slice(
            -2
          )}/${d.getFullYear()}`;
          console.log(d);
          return (
            <li key={item.key}>
              <a href='javascript:void(0)'>
                {item && item.planId !== '' ? (
                  <>
                    <span>{item?.planId?.title}</span>
                  </>
                ) : (
                  ''
                )}

                <span>{item?.amount}</span>
                <span>{item?.credit}</span>
                <span>{formatedDate}</span>
              </a>
            </li>
          );
        })}
      </>
    );
  }
};

export default PaymentMethod;
