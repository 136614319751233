import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Landscape from "../../assets/images/barnd-landscape-logo.png";
import ToggleIcon from "../../assets/images/menu.png";
import CrossIcon from "../../assets/images/close.png";
import ProfileIcon from "../../assets/images/profile-img-standard.png";
import UploadIcon from "../../assets/images/profile-img-upload-icon.png";
import PerspectvIcon from "../../assets/images/logo-icon-blk.png";
import UserProfileIcon from "../../assets/images/user-profile-icon.png";
import PersonalBioIcon from "../../assets/images/personal-bio-icon.png";
import ResetPasswordIcon from "../../assets/images/reset_password.png";
import SettingIcon from "../../assets/images/setting-icon.png";
import ChatIcon from "../../assets/images/chatIcon.svg";
import LogoutIcon from "../../assets/images/logout-icon.png";

import {
  postApi,
  getApi,
  API_CURRENT_USER,
  API_ADD_IMAGE_DOCUMNET_JOBSEEKER,
  API_ADD_DOCUMNET_JOBSEEKER,
  API_All_NOTIFICATION,
} from "../../utils";
import { handelUserLogout, saveUserInfo } from "../../redux/actions";

export default function HeaderMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [ImageUrl, setImageUrl] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [isWishlist, setWishlist] = useState(0);
  const [isActive, setActive] = useState("menu-none");
  const [isMobile, setIsMobile] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [imageFile, setImageFile] = useState(undefined);
  const [profileImage, setProfileImage] = useState("");

  const userType = useSelector((state) => state.userType.userType);
  const userDetail = useSelector((state) => state.UserAuth.userData.payload.user);
  const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
  const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);

  const location = useLocation();

  useEffect(() => {
    getNotification();
    requestDataFromServer();
  }, []);

  useEffect(() => {
    if (imageFile) {
      saveUserData();
    }
  }, [profileImage]);

  const getNotification = () => {
    const axiosConfig = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    getApi(API_All_NOTIFICATION, axiosConfig, setNotificationData, console.error);
  };

  const requestDataFromServer = () => {
    const obj = {
      usertype: userType.payload === "jobPoster" ? 2 : 1,
      _id: userDetail._id,
    };
    postApi(API_CURRENT_USER, obj, userToken, (res) => {
      setUser(res.data.data);
      setImageUrl(userType.payload === "jobPoster" ? res.data.data?.destination : res.data.data?.imagePath);
    }, console.error);
  };

  const handleLogout = () => {
    dispatch(handelUserLogout());
    navigate("/");
  };

  const handleMenuToggle = () => {
    setActive((prev) => (prev === "menu-none" ? "menu-show" : "menu-none"));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setImageFile(file);
      setImageUrl(objectUrl)
      setProfileImage(objectUrl);
    }
  };

  const saveUserData = () => {
    if (!imageFile) {
      toast.error("Upload profile picture!", { position: "top-right", autoClose: 1000 });
      return;
    }

    const formData = new FormData();
    formData.append(userType.payload === "jobPoster" ? "upload" : "image", imageFile);
    formData.append("userId", userId);

    const requestOptions = {
      method: "POST",
      headers: { Authorization: `Bearer ${userToken}`, "type": "front-end", "mode": "1" },
      body: formData,
    };

    fetch(userType.payload === "jobPoster" ? API_ADD_IMAGE_DOCUMNET_JOBSEEKER : API_ADD_DOCUMNET_JOBSEEKER, requestOptions)
      .then((response) => response.json())
      .then(() => {
        toast.success("Your profile data is saved successfully", { position: "top-right", autoClose: 1000 });
        setActive("menu-none");
        // setTimeout(() => window.location.reload(false), 1000);
      })
      .catch(() => {
        toast.error("Oops, something went wrong!", { position: "top-right", autoClose: 1000 });
      });
  };

  const toggleWishList = () => {
    setWishlist((prev) => (prev === 0 ? 1 : 0));
  };

  return (
    <header className="dashboard-header">
      <div className="dashboard-header-before" style={backgroundStyle} />
      <div className="container">
        <div className="row pt-4">
          <div className="col-lg-6 col-sm-6 col-12">
            <div className="header-logo">
              <img
                className="img-fluid"
                src={Landscape}
                onClick={() => navigate("/")}
                style={{ width: "200px" }}
                alt="Landscape"
              />
            </div>
            <div className="header-back" onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </div>
          </div>

          <div className="col-lg-6 col-sm-11 col-12 header-left-cnt">
            <div className="dashboard-nav">
              <div
                className={`notification-bell ${notificationData.length > 0 ? "notification-dot" : ""}`}
                onClick={() => navigate("../notification")}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-bell" aria-hidden="true"></i>
              </div>
              <div className="dashboard-toggle">
                <nav className="navbar">
                  <button
                    className="navbar-toggler"
                    type="button"
                    aria-label="Toggle navigation"
                    onClick={handleMenuToggle}
                  >
                    <img
                      className="navbar-toggler-icon"
                      src={isActive === "menu-none" ? ToggleIcon : CrossIcon}
                      alt="Toggle Menu"
                    />
                  </button>
                </nav>
              </div>

              <div className={isActive}>
                <div className="menu-area">
                  {!isMobile && (
                    <div className="user-profile-img" style={{'cursor': 'pointer'}}>
                      <img
                        alt="Profile"
                        src={ImageUrl || ProfileIcon}
                        width="105px"
                        height="105px"
                        className="rounded-circle float-right"
                        style={{ objectFit: "cover" }}
                      />
                      <div className="file">
                        <img src={UploadIcon} alt="Upload" style={{'cursor': 'pointer'}}/>
                        <input type="file" onChange={handleImageUpload} />
                      </div>
                    </div>
                  )}
                  <ul>
                    <li style={{ fontWeight: 600, marginBottom: -10 }}>
                      {user?.fullName}
                    </li>
                    <li className="d-block d-sm-none">
                      <img src={PerspectvIcon} alt="Home" />
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <img src={UserProfileIcon} alt="Profile" />
                      <Link to="../profileUpdate">Profile</Link>
                    </li>
                    {userType.payload === "jobSeeker" && (
                      <li>
                        <img src={PersonalBioIcon} alt="Personal Bio" />
                        <Link to="../profile">Your Personal Bio</Link>
                      </li>
                    )}
                    {userType.payload === "jobPoster" && (
                      <>
                        <li>
                          <img src="https://img.icons8.com/ios-filled/344/bank-card-back-side.png" alt="Add Credit" />
                          <a
                            href="#!"
                            onClick={() => navigate("../paymentOption", { state: { planType: 1 } })}
                          >
                            Add Credit
                          </a>
                        </li>
                        <li onClick={toggleWishList}>
                          <Link to="../wishlist">
                            <img
                              src="https://img.icons8.com/external-anggara-glyph-anggara-putra/344/external-wishlist-ecommerce-interface-anggara-glyph-anggara-putra.png"
                              width="27px"
                              alt="Wishlist"
                            />
                          </Link>
                          <Link to="../wishlist">Talent List</Link>
                        </li>
                        <li
                          className="coins"
                          onClick={() => navigate("../credit", { state: { planCredit: user?.planCredit || 0 } })}
                        >
                          <i className="fa fa-coins" aria-hidden="true"></i>
                          &nbsp; <b>{user?.planCredit || 0}</b>
                          <span /> 
                          <Link to="../credit">Credit</Link>
                        </li>
                      </>
                    )}
                    <li>
                      <img src="https://img.icons8.com/ios-glyphs/2x/forgot-password.png" alt="Change Password" />
                      <Link to="../chanPassword">Change Password</Link>
                    </li>
                    <li>
                      <img src={ChatIcon} alt="Messages" />
                      <Link to="../chat">Messages</Link>
                    </li>
                    <li onClick={handleLogout}>
                      <img src={LogoutIcon} alt="Logout" />
                      <a href="#!">Logout</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="collapse side-menu" id="navbarToggleExternalContent">
                <div className="p-4 mb-4" style={{ backgroundColor: "#ffd578" }}>
                  <div id="sidebar-wrapper">
                    <ul className="sidebar-nav">
                      <li className="side-menu-list d-flex">
                        <div className="profile-img">
                          <div className="d-block">
                            <div className="profile-image-container">
                              <img src={ProfileIcon} alt="Profile" />
                            </div>
                            <div className="file">
                              <img src={UploadIcon} alt="Upload" />
                              <input type="file" name="file" accept="image/*" onChange={handleImageUpload} />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="side-menu-list d-flex">
                        <a className="menu-list" href="#!">
                          <i className="fa fa-user mr-2" aria-hidden="true" />
                          Profile
                        </a>
                      </li>
                      <li className="side-menu-list d-flex">
                        <a className="menu-list" href="#!">
                          <i className="fa fa-shopping-cart mr-2" aria-hidden="true" />
                          Payment Plans
                        </a>
                      </li>
                      <li className="side-menu-list">
                        <a className="menu-list" href="#!">
                          <i className="fa fa-cc-mastercard mr-2" aria-hidden="true" />
                          Payment Transactions
                        </a>
                      </li>
                      <li className="side-menu-list">
                        <a className="menu-list" href="#!">
                          <i className="fa fa-users mr-2" aria-hidden="true" />
                          About
                        </a>
                      </li>
                      <li className="side-menu-list">
                        <a className="menu-list" href="#!">
                          <i className="fa fa-cog mr-2" aria-hidden="true" />
                          T's & C's
                        </a>
                      </li>
                      <li className="side-menu-list" onClick={handleLogout}>
                        <a className="menu-list" href="#!">
                          <i className="fa fa-sign-out mr-2" aria-hidden="true" />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {location.pathname.includes("/homepage") && (
              <div className="user-profile-img-mobile mt-5">
                <div className="user-profile-img-container">
                  <img
                    src={ImageUrl || ProfileIcon}
                    width="105px"
                    height="105px"
                    className="rounded-circle"
                    style={{ objectFit: "cover" }}
                    alt="Profile"
                  />
                  <div className="file">
                    <img src={UploadIcon} style={{ zIndex: 10 }} alt="Upload" />
                    <input type="file" name="file" onChange={handleImageUpload} />
                  </div>
                </div>
                <h1 style={{ fontWeight: "bold" }}>Hi, {userDetail.fullName}!</h1>
                <p style={{ fontWeight: "bold" }}>Go get'em! Success awaits</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}